@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.R-container {
  @apply w-screen h-screen;
}

.R-header-container {
  @apply fixed w-screen h-fit;
}

.R-parent {
  @apply w-full h-full flex flex-row;
}

.R-form-div {
  /* -ms-overflow-style: none; */
  scrollbar-width: 3px;
  @apply h-full flex flex-col justify-between  pb-[8%] px-24 pt-[3%];
}

/* .R-form-div::-webkit-scrollbar {
  display: none; 
} */

.R-left {
  @apply basis-2/4 w-[50vw] mt-[10vh] pt-5;
}

.R-right {
  @apply basis-2/4 w-[50vw];
}

.slider_parent {
  @apply h-screen w-[50vw];
}

.slider-loop {
  @apply relative;
}

.image-class {
  @apply w-[50vw] object-cover h-screen z-[100] top-[30px] right-[30px];
}

.enlish-tag {
  z-index: 10000 !important;
  @apply top-[50px] right-[50px] text-white;
}

/* .slick-dots {
  @apply left-[30px] absolute bottom-[30px] block w-2/5 p-0 m-0 list-none text-center
} */

.z-index-1000 {
  @apply z-[1000000];
}

/* .slick-dots li {
  @apply relative inline-block w-[5px] h-[5px] rounded-[50%] my-0 mx-[5px] p-0 cursor-pointer bg-[#C9C9C9]
} */

li.slick-active {
  width: 20px !important;
  @apply rounded-[5px] bg-white;
}

.R-slider-desc {
  @apply absolute z-[100000] bottom-[70px] left-[30px] text-white;
}

@media screen and (max-width: 976px) {
  .R-parent {
    @apply flex-col-reverse;
  }

  .R-container {
    @apply w-screen h-fit;
  }

  .R-left {
    @apply basis-full h-full w-screen mt-[6vh] mb-[5vh];
  }

  .R-right {
    @apply h-[70vh] basis-full w-screen;
  }

  .slider_parent {
    @apply h-[70vh] w-screen;
  }

  .image-class {
    @apply w-screen object-cover h-[70vh] z-[100] top-[30px] right-[30px];
  }

  .R-form-div {
    @apply h-fit overflow-y-hidden px-8;
  }

  /* .slick-dots {
    @apply left-8 absolute bottom-[30px] block w-2/5 p-0 m-0 list-none text-center
  } */

  .R-slider-desc {
    @apply absolute z-[100000] bottom-[70px] left-8 text-white;
  }
}
.slick-dotss li.slick-active {
  width: 20px !important;
  border-radius: 5px;
  height: 5px;
  background-color: var(--white);
}
.slick-dotss {
  position: absolute;
  display: block;
  margin: 0px 0px 18px 0px !important;
  padding: 0px 0px 0px 30px !important;
  bottom: 20px;
  list-style: none;
  text-align: start !important ;
}
.slick-dotss li {
  width: 5px;
  height: 5px;
  background: #687e8e;
  margin-left: 10px;
  border-radius: 50%;
}
