.card-session {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
}

.card-session:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.session-tab {
  border-bottom: 2px solid rgba(56, 56, 56, 0.25);
}
