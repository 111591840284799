.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.search-select {
  padding: 10px;
  margin-right: 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 400px;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}

.search-button:hover {
  background-color: skyblue;
}
