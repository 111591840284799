@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.R-container {
  @apply w-screen h-screen;
}

.R-header-container {
  @apply fixed w-screen h-fit;
}

.R-parent {
  @apply w-full h-full flex flex-row;
}

.R-form-div {
  @apply h-full flex flex-col justify-between overflow-y-auto pb-[8%] px-24 pt-[3%];
}

.R-form-div::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
}

.R-left {
  @apply basis-2/4 w-[50vw] mt-[10vh];
}

.R-right {
  @apply basis-2/4 w-[50vw];
}

.slider_parent {
  @apply h-screen w-[50vw];
}

.slider-loop {
  @apply relative;
}

.image-class {
  @apply w-[50vw] object-cover h-screen z-[100] top-[30px] right-[30px];
}

/* .slick-dots {
  display: flex !important;
  @apply left-[30px] absolute bottom-[30px] block w-2/5 p-0 m-0 list-none text-center rtl:w-full  rtl:justify-end rtl:pr-[65px];
} */

.z-index-1000 {
  @apply z-[1000000];
}

/* .slick-dots li {
  @apply relative inline-block w-[5px] h-[5px] rounded-[50%] my-0 mx-[5px] p-0 cursor-pointer bg-[#C9C9C9];
} */

li.slick-active {
  width: 20px !important;
  @apply rounded-[5px] bg-white;
}

.R-slider-desc {
  @apply absolute z-[100000] bottom-[70px] left-[30px] text-white;
}

@media screen and (max-width: 900px) {
  .R-parent {
    @apply flex-col-reverse;
  }

  .R-container {
    @apply w-screen h-fit;
  }

  .R-left {
    @apply basis-full h-full w-screen mt-[6vh] mb-[5vh];
  }

  .R-right {
    @apply h-[70vh] basis-full w-screen;
  }

  .slider_parent {
    @apply h-[70vh] w-screen;
  }

  .image-class {
    @apply w-screen object-cover h-[70vh] z-[100] top-[30px] right-[30px];
  }

  .R-form-div {
    @apply h-fit overflow-y-hidden px-8;
  }

  /* .slick-dots {
    @apply left-8 absolute bottom-[30px] block w-2/5 p-0 m-0 list-none text-center;
  } */

  .R-slider-desc {
    @apply absolute z-[100000] bottom-[70px] left-8 text-white;
  }
}
