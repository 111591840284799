.my-calendar {
  background-color: white;
  border: none;
  border-radius: 4px;
  box-shadow: none !important;
  gap: 10px;
}
.rmdp-day {
  margin: 5px;
}
.rmdp-week-day {
  color: black !important;
}
.rmdp-left i {
  margin-left: 0px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.rmdp-header-values {
  margin-left: 0px;
}
.rmdp-arrow-container {
  margin-left: 250px;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  color: #ffff !important;
  box-shadow: none !important;
  background-color: rgba(2, 164, 234, 1) !important;
  border-color: rgba(2, 164, 234, 1) !important;
  border-radius: 0% !important;
  justify-content: center !important;
  display: flex !important;
}
/* .sd {
  @apply py-[5px] !px-[5px] h-[30px] w-full;
} */
/* .rmdp-day span:not(.highlight):hover {
  background-color: rgba(2, 164, 234, 1) !important;
  box-shadow: none !important;
  color: #ffffff !important;
  border-color: rgba(2, 164, 234, 1) !important;
  height: 30px !important;
  width: 30px !important;
} */

/* .rmdp-day span {
  color: white;
  background-color: white;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 0px;
}
*/
.rmdp-day span:not(.highlight):hover {
  border-radius: 0% !important;
}
.rmdp-day.rmdp-today span {
  border-radius: 0% !important;
}

.rmdp-calendar {
  @apply rtl:font-Almarai;
}
