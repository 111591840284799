/* Hide scrollbar for Chrome, Safari and Opera */
.form-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.form-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* @apply h-full flex flex-col justify-between overflow-y-auto  */
}

.border-line {
  border: 1px solid rgba(56, 56, 56, 0.25);
}
