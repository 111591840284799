/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
}

input:checked ~ .dot-2 {
  @apply bg-primary;
}
.css-1d8n9bt {
  @apply !overscroll-none !overflow-visible;
}
