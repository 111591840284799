.login_paragraph_text {
  @apply font-normal text-16 lg:text-17.5 text-gray font-general rtl:font-Almarai;
}
.captcha {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @apply ltr:mr-2 rtl:ml-2 appearance-none  italic  w-[75%] text-20 flex justify-center items-center bg-[#212147]  h-[46px] font-general rtl:font-Almarai rounded text-white;
}

.captcha-container {
  @apply w-full flex;
}
.input-width {
  @apply w-1/2 ltr:pl-2 rtl:pr-2;
}
.input-width-captcha {
  @apply w-1/2 ltr:pr-2 rtl:pl-2;
}
.login-container {
  @apply w-full max-w-lg m-auto bg-white rounded-lg py-12 px-14;
}
.captcha-refresh {
  @apply h-full border-gray border h-[46px] w-[40px] rounded border-opacity-50 flex justify-center items-center;
}
.captcha-refresh-inner {
  @apply h-full h-10 w-[40px]  flex justify-center items-center;
}
@media screen and (max-width: 560px) {
  .captcha {
    @apply mb-5 w-1/2;
  }
  .login-container {
    @apply mr-5 ml-5 py-8 px-6 max-h-[82vh] m-auto  rounded-lg overflow-y-auto mt-[111px];
  }
  .captcha-container {
    @apply block;
  }
  .input-width {
    @apply w-full ltr:pl-0 rtl:pr-0;
  }
  .input-width-captcha {
    @apply w-full ltr:pr-0 rtl:pl-0;
  }
}
.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary);
  @apply w-5 h-5 animate-spin rounded-[50%] mx-4;
}
