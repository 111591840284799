.subscription-container {
  @apply w-full h-auto  px-20;
}
.subscription-card {
  /* height: inherit;
     */
  min-height: fit-content;
  height: 100%;
  box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
  @apply min-w-[25vw] w-full p-8  mx-2.5 bg-white rounded-lg text-[#687E8E] overflow-y-auto;
}
.subscription-card:hover {
  @apply bg-[#212147] text-white;
}
.subscription-icon-div {
  @apply bg-[#02A4EA] rounded-2xl flex justify-center items-center;
}

.subscription-card:hover .subscription-icon-div {
  @apply bg-[#9EE0E6] rounded-2xl;
}
.subscription-card-title {
  @apply w-fit flex flex-col px-6;
}
.subscription-card-subtitle {
  @apply text-[#153243];
}
.subscription-card:hover .subscription-card-subtitle {
  @apply text-white;
}
.subscription-card-price {
  @apply w-fit  mt-5 font-satoshi rtl:font-Almarai text-[#153243] font-normal text-[50px];
}
@media screen and (max-width: 920px) {
  .subscription-card-price {
    @apply w-min  mt-5 font-satoshi rtl:font-Almarai text-[#153243] font-normal text-[50px];
  }
  .subscription-card {
    min-height: 90vh;
    height: fit-content;
  }
}
.subscription-card:hover .subscription-card-price {
  @apply text-white;
}
.subscription-card-annual {
  @apply flex flex-row pb-4  font-satoshi rtl:font-Almarai text-[#153243] font-semibold text-xl;
}
.subscription-card:hover .subscription-card-annual {
  @apply text-white;
}
.subscription-page-card-desc {
  @apply w-full h-fit flex  font-general rtl:font-Almarai  text-base font-normal text-[#687E8E];
}
.subscription-card:hover .subscription-page-card-desc {
  @apply text-white;
}
.get-started-btn {
  background-color: #02a4ea !important;
  border: none !important;
  @apply text-white font-normal text-16 font-general rtl:font-Almarai px-4  border rounded;
}
.subscription-card:hover .get-started-btn {
  background-color: #9ee0e6 !important;

  @apply text-[#153243];
}
.subscription-card-feature {
  border-bottom: 1px solid rgba(56, 56, 56, 0.25);
  @apply w-full text-[#153243] pt-10 font-bold text-lg pb-3 flex flex-col;
}
.subscription-card:hover .subscription-card-feature {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  @apply text-white;
}
.subscription-card-add {
  @apply w-full text-[#153243] pt-5 font-bold text-lg pb-3 flex flex-col;
}
.subscription-card:hover .subscription-card-add {
  @apply text-white;
}
.offer-card {
  box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
  @apply h-56 w-full rounded-[10px] p-6 flex flex-col justify-between;
}
.faq-card {
  transition: min-height 0.3s ease-in-out;
  border: 1px solid rgba(56, 56, 56, 0.25);
  @apply w-full flex-col flex items-center min-h-[4.5rem] h-fit  p-4 rounded font-satoshi rtl:font-Almarai font-bold text-[22px];
}
.max {
  transition: min-height 0.3s ease-in-out;
  /* height: 200px; */
  min-height: 150px;
}
.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate.down {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.faq-desc {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.26s ease;
  height: fit-content;
}
.faq-desc-hidden {
  transform: scaleY(0);
  height: 0;
}
.subscription-card-container {
  @apply w-full grid grid-cols-3 gap-4  mt-12;
}
.offer-card-container {
  @apply w-full mt-12 justify-center grid grid-cols-3 gap-6;
}
.faq-card-container {
  @apply w-full mt-12 justify-center grid grid-cols-2 gap-6 pb-10;
}
.subscription-slider {
  display: none;
}
@media screen and (max-width: 1200px) {
  /* .subscription-card-container {
      @apply hidden;
    } */
  .subscription-card-container {
    @apply grid grid-cols-2 gap-6;
  }
  .offer-card-container {
    @apply grid-cols-2;
  }
  .subscription-card {
    width: 100%;
  }
  /* .subscription-card {
      width: 80vw;
      @apply mb-4;
    } */
  /* .subscription-slider {
      display: block;
    } */
}
@media screen and (max-width: 750px) {
  .subscription-container {
    @apply px-5;
  }
  .offer-card-container {
    @apply grid-cols-1;
  }
  .faq-card-container {
    @apply grid-cols-1;
  }
  .faq-card {
    @apply rounded-lg;
  }
  .subscription-card-container {
    @apply hidden;
  }
  .subscription-card {
    width: 80vw;
    @apply mb-4;
  }
  .subscription-slider {
    display: block;
  }
}
@media screen and (max-width: 560px) {
}
.subscription-slider .slick-initialized .slick-slide {
  display: flex;
}
.subscription-slider .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  /* display: flex; */
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 30px;
}
.subscription-slider .slick-dots {
  margin: 0px 0px 18px 0px !important;
  padding: auto !important;
}
.subscription-slider .button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 70px;
  text-align: center;
  margin-top: 20px;
}
.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: rgba(56, 56, 56, 0.25);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 6px;
}

.button__bar li.slick-active button {
  background-color: #02a4ea;
  width: 18px;
}
