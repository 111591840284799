.payment-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @apply w-[70vw] h-[80vh]  bg-white rounded-lg  flex flex-row;
}
.payment-card-inner {
  border-left: 1px solid rgba(56, 56, 56, 0.25);
}
.payment-inner-bottom {
  border-top: 1px solid rgba(56, 56, 56, 0.25);
}
