.coach-session-container {
  /* height: calc(100vh - 4rem); */

  @apply w-full px-20 h-fit  flex justify-between pt-16 overflow-y-auto flex-row;
}
.price-input-container {
  @apply w-[30%];
}
.coaching-hr-container {
  @apply w-full flex gap-3 flex-row;
}
.coaching-hr-container-hour {
  @apply w-[30%];
}
.coaching-hr-container-day {
  @apply w-[40%];
}
.hour-input-container {
  @apply w-[70%] gap-3 flex;
}
.hour-input-container-box {
  @apply w-[30%];
}
.hour-input-container-AM {
  @apply w-[20%];
}
.session-form-container {
  /* box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.15); */
  @apply w-[100%] h-fit mb-12 p-8;
}
.session-image-container {
  @apply w-full h-fit flex;
}
.session-image-desc {
  @apply h-56 w-full px-5 py-8 flex flex-col justify-between;
}
.session-mobile-menu {
  @apply h-14 w-full p-5 px-0 mb-3 font-satoshi rtl:font-Almarai relative flex justify-between font-bold text-2xl;
}
.session-mobile-menu-container {
  @apply hidden;
}
.mobile-btn-div {
  @apply hidden;
}
@media screen and (max-width: 560px) {
  .coach-session-container {
    @apply px-5 pt-6;
  }
  .price-input-container {
    @apply w-[50%];
  }
  .hour-input-container {
    @apply w-[60%];
  }
}
.quill {
  border: 1px solid #687e8e;
  /* border-radius: 4px; */
  @apply flex flex-col-reverse font-general rtl:font-Almarai rounded;
}
.ql-toolbar.ql-snow {
  @apply flex justify-end font-general rtl:font-Almarai p-2 border-0;
}
.ql-container.ql-snow {
  @apply border-0;
}
.ql-toolbar.ql-snow .ql-formats {
  @apply m-0;
}
.ql-container {
  @apply font-general rtl:font-Almarai  text-17 text-opacity-80;
}
.ql-editor.ql-blank::before {
  content: attr(data-placeholder);
  font-style: normal;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.react-multi-react-tags .css-1rhbuit-multiValue {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #cccccc;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}
.coach-session-nav {
  @apply w-[24%] h-[74vh] ltr:pr-5 rtl:pl-5 flex flex-col justify-between;
}
.package-dropdown-row {
  @apply flex w-full flex-row grid grid-cols-2 gap-6;
}
.session-image-default {
  @apply w-[30rem] h-56 rounded bg-[#F6F8F5] flex justify-center items-center;
}
.session-image {
  @apply w-[30rem];
}
.session-upload {
  @apply w-full flex items-center;
}
.session-upload-mobile {
  @apply w-full flex items-center hidden;
}
.package-assessment-row {
  @apply flex w-full justify-between;
}
.package-session-row {
  @apply flex w-full justify-between;
}
.package-assessment-input {
  @apply w-[24%];
}
.package-assessmentTitle-input {
  @apply w-[54%] mb-8;
}
.package-week-input {
  @apply w-[19%] mb-8;
}
.package-sessionTitle-input {
  @apply w-[44%] mb-8;
}
.package-days-input {
  @apply w-[14%] mb-8;
}
.package-select-input {
  @apply w-[49%] mb-8;
}
@media screen and (max-width: 1014px) {
  .coach-session-nav {
    @apply hidden;
  }
  .session-form-container {
    box-shadow: none;
    @apply w-full p-0;
  }
  .session-mobile-menu-container {
    @apply block;
  }
  .mobile-btn-div {
    @apply block;
  }
  .coach-session-container {
    @apply flex-col;
  }
  .coaching-hr-container-day {
    @apply w-[100%];
  }
  .package-assessment-row {
    @apply flex w-full flex-col;
  }
  .package-assessment-input {
    @apply w-full;
  }
  .package-assessmentTitle-input {
    @apply w-full;
  }
  .package-week-input {
    @apply w-full;
  }
  .package-session-row {
    @apply flex w-full flex-col border-t-[1px] border-[#cccccc] pt-8;
  }
  .package-sessionTitle-input {
    @apply w-full;
  }
  .package-days-input {
    @apply w-full;
  }
  .package-select-input {
    @apply w-full;
  }
}

@media screen and (max-width: 750px) {
  .session-image-desc {
    @apply px-0 pt-0 h-fit;
  }
  .session-image-container {
    @apply flex-col-reverse;
  }
  .session-image-default {
    @apply w-full;
  }
  .session-image {
    @apply w-full;
  }
  .session-upload {
    @apply hidden;
  }
  .session-upload-mobile {
    @apply w-full flex items-center block;
  }
  .coaching-hr-container {
    @apply flex-col;
  }
  .coaching-hr-container-hour {
    @apply w-[50%] pt-3;
  }
  /* .hour-input-container {
      @apply grid  grid-cols-2 gap-3;
    } */
  .hour-input-container {
    @apply flex-wrap;
  }
  .hour-input-container-box {
    @apply w-[55%];
  }
  .hour-input-container-AM {
    @apply w-[35%];
  }
  .package-dropdown-row {
    @apply flex w-full flex-row grid grid-cols-1 gap-0;
  }
}
.coach-session-dropdown-container {
  box-shadow: 0px 2px 15px rgb(47 74 110 / 5%);
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  top: 50px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--white);
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -6px;
  bottom: -5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

.content-loader {
  overflow: hidden;
  background: #000;
  position: relative;

  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px;
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
.ash-loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #687e8e;
  @apply w-5 h-5 animate-spin rounded-[50%];
}
.package-card-image {
  /* h-[8.375rem] */
  object-fit: cover;
  @apply w-[8.375rem]  rounded-l-xl;
}
.package-card-days {
  @apply block;
}
.package-card-days-mobile {
  @apply hidden;
}
.package-card-price-mobile {
  @apply hidden;
}
@media screen and (max-width: 450px) {
  .package-card-image {
    @apply h-[4.375rem] w-[4.375rem]  rounded-lg ltr:ml-2 rtl:mr-2 mt-2;
  }
  .package-card-days {
    @apply hidden;
  }
  .package-card-days-mobile {
    @apply w-full flex justify-between font-normal text-xs text-[#687E8E];
  }
  .package-card-price-mobile {
    @apply block;
  }
}
