@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary: #00a5ec;
  --secondary: #153243;
  --gray: #687e8e;
  --info: #c3cbd1;
  --white: #ffffff;
  --light-black: #5d707b;
  --yellow: #f5b239;
  --light-red: #e00000;
  --red: #bc455e;

  --footer-bg: #212147;
  --black: #383838;
  --footer-bg: #212147;
  --light-green: #9ee0e6;
  --skyBlue: #9ee0e6;
  /* --red: #bc455e; */
  --light-blue: #9ee0e6;
  --dark-blue: #212147;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-btn {
  @apply mr-5 inline-block p-3 mb-2 bg-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
}

.btn-small {
  @apply font-normal text-16 font-general rtl:font-Almarai px-4 py-2 border rounded;
}

.btn-submit {
  @apply bg-primary w-full py-3 px-4 text-18 text-white font-bold font-satoshi rtl:font-Almarai rounded flex justify-center items-center;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.paragraph {
  @apply font-normal font-general rtl:font-Almarai text-17.5 text-gray mb-4;
}

.heading_large {
  @apply font-bold font-satoshi rtl:font-Almarai text-34 text-secondary mb-4;
}

.heading_small {
  @apply font-black font-satoshi rtl:font-Almarai text-28 text-secondary mt-9 mb-4;
}

.question_p {
  @apply font-bold text-sm font-satoshi rtl:font-Almarai;
}

.cancel-btn {
  @apply font-bold text-18 font-satoshi rtl:font-Almarai rounded lg:w-48 h-14;
}

.next-btn {
  @apply font-bold text-18 font-satoshi rtl:font-Almarai bg-primary w-48 h-14 text-white rounded;
}

.next-btn-large {
  @apply font-bold text-18 font-satoshi rtl:font-Almarai bg-primary mb-3 w-full h-14 text-white rounded;
}

.next-btn-large {
  @apply font-bold text-18 font-satoshi rtl:font-Almarai bg-primary w-full h-14 text-white rounded;
}
@layer components {
  .p-text {
    @apply font-normal font-general rtl:font-Almarai text-base text-gray my-4;
  }
}
.landing-paragraph-client {
  @apply font-general rtl:font-Almarai font-normal text-16 sm:text-17.5;
}

.landing-heading-client {
  @apply rtl:font-Almarai font-bold text-34 text-secondary mb-4;
}

.landing-paragraph-client {
  /* text-light-black */
  @apply font-general rtl:font-Almarai font-normal text-17.5;
}

.footer-list {
  @apply text-base font-normal text-white not-italic my-3;
}
.footer-head {
  @apply block text-xl not-italic font-medium mb-6;
}
.icon-btn {
  @apply mr-5 inline-block p-3 mb-2 bg-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
}

/* @at-root */
.header_link_text {
  @apply font-normal text-16 font-general rtl:font-Almarai;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.boxShadow {
  box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.15);
}

.btn_auth {
  @apply bg-primary text-white font-general rtl:font-Almarai text-16 rounded lg:w-[85px] h-[37px];
}
.btn {
  @apply font-general rtl:font-Almarai font-semibold text-white  rounded;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}
.slick-dots li.slick-active {
  width: 20px !important;
  border-radius: 5px;
  height: 5px;
  background-color: transparent;
}
.slick-dots {
  position: absolute;
  display: block;
  margin: 0px 0px 18px 0px !important;
  padding: 0px 0px 0px 40px !important;
  list-style: none;
  text-align: start !important ;
}
.category .css-j9x72g-control {
  height: 52px;
  outline: 1px solid #38383840;
  /* z-index: -1; */
}
.category2 .css-j9x72g-control {
  height: 52px;
  outline: 1px solid #38383840;
  z-index: -1;
}
.category .css-1r1xfh1-control:hover {
  height: 52px;
}
input:focus {
  outline: 0;
  box-shadow: none;
}
.ql-editor:focus-visible {
  outline: none;
}
textarea:focus-visible {
  outline: none;
}
.boxShadow {
  box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.15);
}
input::placeholder {
  color: #ffffff;
}
.search-input::placeholder {
  color: #686868;
}
.loader_pulse {
  @apply animate-pulse bg-[#f4f4f5];
}
/* ::-webkit-scrollbar {
  color: #00a5ec;
  @apply w-2 bg-white;
} */
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0;
  margin-right: auto;
}
.w-inherit {
  width: inherit;
}
.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.image-inside-container {
  width: 100%;
  object-fit: contain;
  max-height: 340px;
  backdrop-filter: blur(8px);
}