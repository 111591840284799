.relavent .css-j9x72g-control {
  min-height: 52px !important;
  outline: var(--borderColor);
  /* width:225px!important; */
}
.relavent .css-j9x72g-control:target {
  height: 52px !important;
  outline: var(--borderColor);
}
.relavent .css-j9x72g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 52px !important;
  outline: 1px solid #687e8e;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  padding: 3px;
  border: 0;
}
