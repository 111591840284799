.about-us-body-page-footer-btn {
  height: 37px;
  border-radius: 0.25rem;
  background-color: var(--primary);
  font-family: 'General Sans', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: var(--white);
  height: 54px;
  width: 295px;
}
.about-us-body-page-footer-btn-without-bg {
  height: 37px;
  border-radius: 0.25rem;
  background-color: transparent;
  font-family: 'General Sans', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: black;
  border: 1px solid #153243;
  height: 54px;
  width: 295px;
}

.background-img-about {
  background-image: url('/public/assets/aboutUsPage/background-img.png');
}
