@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.registrationStep-container01 {
  @apply grid lg:flex lg:overflow-hidden lg:h-screen absolute -z-10 w-full;
}
.registrationStep-container02 {
  @apply justify-center items-center;
}

.registrationStep-container03 {
  @apply lg:w-8/12 lg:mt-20 bg-white relative h-screen rounded-lg px-5 lg:px-24 order-last lg:order-first  lg:overflow-y-auto;
}
.add_more {
  @apply text-primary font-bold font-satoshi rtl:font-Almarai text-16;
}
.progress_bar {
  @apply w-full bg-info h-1 mt-1 mb-12;
}
.step {
  @apply font-satoshi rtl:font-Almarai font-bold text-17 text-secondary mt-16;
}
.skip-btn {
  @apply font-bold text-18 font-satoshi rtl:font-Almarai border border-gray mr-3 w-48 h-14 text-gray rounded;
}

.skip-btn-large {
  @apply font-bold text-18 font-satoshi rtl:font-Almarai border border-gray mb-3 w-full h-14 text-gray rounded;
}
.paragraph-educational {
  @apply font-normal font-general rtl:font-Almarai text-17.5 text-gray mb-12;
}

.slick-dots {
  position: absolute;
  display: block;
  margin: 0px 0px 18px 0px !important;
  padding: 0px 0px 0px 40px !important;

  list-style: none;
  text-align: start !important ;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px 0px 0px 0px !important;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active {
  margin-right: 11px !important;
  margin-left: -4px !important;
  margin-left: -3px !important;
}
.containerss {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
input[type='file'] {
  @apply absolute top-2 left-12 text-17 text-primary;
}
.button-wrap {
  position: relative;
}
.button {
  @apply inline-block bg-gray rounded px-4 py-2 text-white text-14 font-bold;
}
.registrationStep-container03::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  background-color: rgba(56, 56, 56, 0.25);
}

.registrationStep-container03::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #00a5ec;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
