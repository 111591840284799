/* Hide scrollbar for Chrome, Safari and Opera */
.form-header::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.form-header {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* min-height: calc(100vh - 30.25rem); */
  min-height: 56vh;
  height: fit-content;
  @apply flex flex-col justify-between overflow-y-auto;
}
.close-button {
  @apply bg-closeRed w-full py-3 px-4 text-18 text-white font-bold font-satoshi rtl:font-Almarai rounded flex justify-center items-center;
}

.profile-mobile-menu {
  @apply h-14 p-5 px-0 font-satoshi rtl:font-Almarai relative flex justify-between font-bold text-2xl hidden;
}

@media screen and (max-width: 744px) {
  .profile-mobile-menu {
    @apply flex justify-end;
  }
}

.profile-dropdown-container {
  box-shadow: 0px 2px 15px rgb(47 74 110 / 5%);
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  top: 50px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--white);
}

.selected-tab {
  border-bottom: 1px solid rgba(56, 56, 56, 0.25);
}
.selected {
  border-bottom: 2px solid var(--primary);
}
