.range-input {
  display: flex;
  align-items: center;
}

.sliderc::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 4px solid #153243;
  transition: 1;
  z-index: 100;
}

/* .slider input::-moz-range-progress {
    background: green;
  } */

.range-value {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 4px;
  width: 200px;
  background: rgba(56, 56, 56, 0.25);
  border-radius: 5px;
  background-image: linear-gradient(#153243, #153243);
  background-repeat: no-repeat;
  z-index: 1000;
}
input[type='range']::-webkit-slider-thumb {
  transition: background 0s ease-in-out;
  z-index: 15;
}
