input[type='date']:focus + label,
input[type='date']:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100;
}

input[type='date']::before {
  @apply w-full;
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */

.sd-container {
  border: 1px solid black;
  @apply relative float-left;
}
/* 
.calendar-div.rmdp-day.rmdp-selected span:not(.highlight) {
  border-radius: 0% !important;
  flex: auto;
  justify-content: center;
  place-items: center;
}
.sd {
  @apply py-[5px] px-[10px] h-[30px] w-full;
}
.calendar-div.rmdp-day.rmdp-today span {
  border-radius: 0% !important;
  flex: auto;
  justify-content: center;
  place-items: center;
}
.calendar-div.rmdp-day span {
  border-radius: 0% !important;
  flex: auto;
  justify-content: center;
  place-items: center;
} */
.open-button {
  @apply absolute top-[3px] right-[11px] w-[25px] h-[25px] bg-white pointer-events-none;
}

.open-button button {
  border: none;
  background: transparent;
}

.change-placeholder:focus + label,
.change-placeholder:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100;
}

.change-placeholder:focus ~ .datelabel,
.change-placeholder:not(:placeholder-shown) + .datelabel {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-4 scale-100;
}
.lok:focus + label,
.lok:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100;
}
.open-button {
  @apply absolute  top-1.5 h-6.1 bg-white pointer-events-none;
}
