.home-card {
  box-shadow: 0px 10px 25px rgba(72, 72, 181, 0.08);
  @apply w-full h-[10rem] p-6  rounded-2xl;
}
.draft-card {
  box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
  @apply h-fit w-full rounded-lg flex;
}
.progress-bar {
  background-color: rgba(56, 56, 56, 0.25);
  @apply h-3.5 w-full rounded-sm;
}
.notification-box ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  background-color: rgba(56, 56, 56, 0.25);
}

.notification-box ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #212147;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.notification-box {
  border: 1px solid rgba(56, 56, 56, 0.25);
  box-shadow: 0px 5px 10px rgba(103, 103, 103, 0.1);
  @apply w-[69%] p-6 min-h-[25rem]  rounded-lg h-5;
}
.schedule-box {
  border: 1px solid rgba(56, 56, 56, 0.25);
  box-shadow: 0px 5px 10px rgba(103, 103, 103, 0.1);
  @apply w-[29%] p-6 min-h-[25rem]  rounded-lg h-5;
}
.notification-box-heading {
  border-bottom: 0.8px solid rgba(56, 56, 56, 0.25);
  @apply w-full h-9 flex;
}
.notification-list-card {
  border-bottom: 1px solid rgba(56, 56, 56, 0.25);
  @apply h-auto w-full p-5;
}
.schedule-box-heading {
  border-bottom: 0.8px solid rgba(56, 56, 56, 0.25);
  @apply w-full h-9 flex justify-between;
}
.schedule-inner-box {
  background: rgba(158, 224, 230, 0.1);
  @apply w-[98%] h-[5.25rem] rounded-r;
}
.home-container {
  @apply w-full h-auto  px-20;
}
.home-card-row {
  @apply pt-20 w-full grid grid-cols-4 gap-6;
}
.draft-card-image {
  @apply w-[11.75rem] h-[9.938rem] object-cover;
}
.service-progress-row {
  @apply flex flex-row items-center  text-base font-satoshi rtl:font-Almarai font-bold;
}
.draft-card-txt-container {
  @apply w-full flex flex-col justify-between;
}
.draft-card-actions {
  @apply flex;
}
.draft-card-actions-mobile {
  @apply hidden;
}
.notification-box-row {
  @apply pt-10 w-full flex mb-20 flex-row justify-between;
}
.notification-img {
  @apply w-[5.3rem] h-[5.3rem] object-cover;
}
.notification-list-desc {
  @apply w-full flex flex-col justify-between ltr:pl-5 rtl:pr-5;
}
.notification-box-card {
  width: calc(100% + 1.5rem);
  @apply h-[20rem] pt-[1rem] ltr:pr-6 rtl:pl-6 overflow-y-scroll;
}
.view-all-btn {
  @apply w-[7.313rem] h-[2.313rem];
}
.home-footer {
  @apply w-full bg-darkBlue p-20 text-white flex flex-row;
}
.home-footer-company {
  @apply flex w-[20%]  flex-col;
}
.home-footer-support {
  @apply flex w-[20%] flex-col;
}
.home-footer-translation {
  @apply flex justify-start items-start;
}
.home-footer-icon {
  @apply flex w-[60%] h-[14rem] justify-between flex-col;
}
.draft-heading {
  @apply text-[#153243] mb-10 font-satoshi rtl:font-Almarai font-bold text-3xl;
}
.service-row {
  @apply pt-20 w-full flex flex-col;
}
@media screen and (max-width: 1118px) {
  .home-card-row {
    @apply grid-cols-2;
  }
  .notification-box-row {
    @apply flex-col;
  }
  .notification-box {
    @apply w-full p-3 mb-[3rem];
  }
  .schedule-box {
    @apply w-[100%];
  }
  .notification-box-card {
    width: calc(100% + 0.75rem);
    @apply h-[20rem] pt-[1rem] ltr:pr-3 rtl:pl-3 overflow-y-scroll;
  }
}
@media screen and (max-width: 768px) {
  .home-container {
    @apply px-5;
  }
  .home-card-row {
    @apply grid-cols-1;
  }
  .service-progress-row {
    @apply flex-col mt-2 items-start;
  }
  .draft-card-txt-container {
    @apply p-0 pl-2;
  }
  .draft-card-actions {
    @apply hidden;
  }
  .draft-card-actions-mobile {
    @apply w-full justify-end block flex mt-4;
  }
  .progress-bar {
    @apply mt-2;
  }

  .notification-img {
    @apply w-[2.5rem] h-[2.5rem] object-cover;
  }
  .notification-list-desc {
    @apply ltr:pl-3 rtl:pr-3;
  }
  .view-all-btn {
    @apply mt-2;
  }
  .draft-heading {
    @apply text-2xl;
  }
  .home-card-row {
    @apply pt-10;
  }
  .service-row {
    @apply pt-10 w-full flex flex-col;
  }
  .notification-box-row {
    @apply pt-10 mb-10;
  }
  .home-footer {
    @apply px-5 py-10;
  }
}
@media screen and (max-width: 500px) {
  .draft-card {
    box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
    @apply p-2;
  }
  .draft-card-image {
    @apply w-[4.5rem] h-[4.5rem] rounded object-cover;
  }
}

@media screen and (max-width: 690px) {
  .home-footer {
    @apply flex-col;
  }
  .home-footer-icon {
    @apply w-full;
  }
  .home-footer-company {
    @apply mt-10 w-full;
  }
  .home-footer-support {
    @apply mt-10 w-full;
  }
  .home-footer-translation {
    @apply mt-10;
  }
}
