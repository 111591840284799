.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
/* h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
} */

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(white);
}

.accordion-title:hover {
  background-color: var(white);
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: white;
}
