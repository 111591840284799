.search-input:focus-visible {
  outline: none;
}
.my-upload-card-grid {
  @apply w-full grid grid-cols-4 gap-6 mt-10;
}
.my-upload-search-box {
  @apply border  border-[#687e8e] rounded flex  items-center;
}
@media screen and (max-width: 1213px) {
  .my-upload-card-grid {
    @apply grid-cols-3;
  }
}
@media screen and (max-width: 860px) {
  .my-upload-card-grid {
    @apply grid-cols-2;
  }
  .my-upload-search-box {
    @apply w-full;
  }
}
@media screen and (max-width: 600px) {
  .my-upload-card-grid {
    @apply grid-cols-1;
  }
}
