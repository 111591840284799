/* Hide scrollbar for Chrome, Safari and Opera */
.form-header::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .form-header {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @apply h-full flex flex-col justify-between overflow-y-auto 
    
  }