.session-page-container {
  /* height: calc(100vh - 4rem); */

  @apply w-full h-fit;
}
.session-page-content {
  @apply p-14 w-full flex flex-col justify-start  items-start;
}
.session-page-title {
  @apply w-full h-fit flex text-center justify-center font-satoshi rtl:font-Almarai text-3xl pb-6 font-bold items-center;
}
.session-page-desc {
  @apply w-[70%] mx-auto h-fit flex pb-12 justify-center font-general rtl:font-Almarai text-center text-base font-normal text-[#687E8E];
}
.session-card-row {
  @apply w-full h-fit flex items-center justify-center;
}
.session-card-inactive {
  box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
  border-radius: 8px;
  @apply w-48 h-[22rem] mx-2.5 p-6;
}
.session-card-active {
  box-shadow: 0px 10px 25px rgba(0, 0, 51, 0.08);
  border-radius: 8px;
  @apply w-48 h-[22rem] mx-2.5 p-6 bg-[#212147]  text-white;
  /* .session-card-content {
    @apply text-white;
  } */
}
.session-card-content {
  @apply text-[#687E8E];
}
.session-card-inactive:hover {
  @apply bg-[#212147]  text-white;
}
.session-card-content {
  @apply text-white;
}
.session-btn-row {
  @apply w-full flex pt-10 justify-center items-center;
}
@media screen and (max-width: 560px) {
  .session-page-container {
    @apply flex flex-col;
  }
  .session-page-content {
    @apply px-5 py-8;
  }
  .session-page-title {
    @apply text-2xl;
  }
  .session-page-desc {
    @apply w-full pb-8;
  }
  .session-btn-row {
    @apply flex-col-reverse pt-6;
  }
}
@media screen and (max-width: 750px) {
  .session-page-container {
    @apply h-fit;
  }
  .session-card-row {
    @apply flex-col;
  }
  .session-card-active {
    @apply w-full my-2.5 h-fit;
  }
  .session-card-inactive {
    @apply w-full my-2.5 h-fit;
  }
  .session-btn-row {
    @apply w-full;
  }
}
